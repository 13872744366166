
#projects {
    display: flex;
    flex-direction: column;
    align-self: center;
    min-height: calc(100vh - 222px);
}

.project {
    padding-bottom: 22px;
    margin-bottom: 25px;
}

footer {
    margin-top: auto;
}