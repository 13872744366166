

.bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10vw;
    padding-right: 10vw;
}
.container1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-grow: 1;
}
.container2 {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.name {
    font-family: 'Lato', sans-serif;

}
.links {
    margin-top:30px;
    padding-left: 0;
    font-family: 'Inconsolata', monospace;
}