main {
    display: flex;
    flex-direction: column;
    align-content: center;
}

#resumeSection {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

li {
    margin-bottom: 20px;
}

svg {
    overflow: visible;
}

#skills {
    margin-bottom: 10px ;
    list-style-type: none;
    font-size: larger;
}

h2 + ul {
    width: 750px;
    word-wrap: break-word;
}