
.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color:#2b1a1a;
    min-height: calc(100vh - 80px);
}

h1 {
    font-weight: lighter;
    padding-top: 10px;
    text-align: center;
}

.bodyContent {
    margin-right: 100px;
}

section {
    display: flex;
    margin-left: 60px;
    margin-bottom: 20px;
    flex-direction: row;
}

h2 {
    font-weight: normal;
    font-size: x-large;
    margin-bottom: 5px;
}
svg {
    padding-right: 10px;
    overflow: visible
}

p {
    margin-top:0px;
}

#lastSection {
    margin-bottom: 0px;
}
footer {
    margin-top: auto;
}

#advent {
    color: rgb(217, 46, 58);
    text-decoration: none
}